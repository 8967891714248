
/* body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
  }
  
  .App {
    text-align: center;
    padding: 20px;
  }
  
  header {
    background-color: #282c34;
    padding: 20px;
    color: white;
    font-size: 1.5rem;
  } */
  
  button {
    padding: 10px 20px;
    margin: 10px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    font-size: 1rem;
    margin-bottom: 10px;
  }
  