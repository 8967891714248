/* src/components/Navbar.css */
.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px; /* Ensure this padding is wide enough */
    background: #161b22;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 2;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  .logo {
    font-size: 1.5rem;
    color: #58a6ff;
  }
  
  .nav-link {
    margin-left: 20px;
    font-size: 1.3rem;
    color: #c9d1d9;
    text-decoration: none;
  }
  
  .button {
    background: #58a6ff;
    color: #0d1117;
    padding: 10px 20px;
    border: 1px solid #58a6ff;
    border-radius: 6px;
    cursor: pointer;
    text-decoration: none;
  }
  
  .user-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 20px; /* Add margin to prevent it from touching the edge */
  }
  