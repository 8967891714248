/* src/pages/EditUnitPage.css */
.edit-unit-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .title {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
  }
  
  .subtitle {
    color: #ffffff;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  .deck-list {
    list-style-type: none;
    padding: 0;
  }
  
  .deck-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f9f9f9;
    padding: 12px 20px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .edit-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .edit-button:hover {
    background-color: #0056b3;
  }
  
  .form-group {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }
  
  .input {
    flex-grow: 1;
    padding: 10px;
    font-size: 16px;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .add-button {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 10px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-button:hover {
    background-color: #218838;
  }
  
  .loading {
    text-align: center;
    margin-top: 50px;
    font-size: 20px;
    color: #777;
  }
  


  /* EditUnitPage.css */

/* Set all text to white by default */



.title {
  font-size: 24px;
  color: #ffffff;
}

.subtitle {
  font-size: 18px;
  color: #ffffff;
}

.deck-list {
  list-style-type: none;
  padding: 0;
}

.deck-item {
  background-color: #4e4e4e; /* Greyish background for each deck */
  color: white; /* White text color */
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-button {
  background-color: #2d2d2d; /* Darker grey for the button */
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.edit-button:hover {
  background-color: #1c1c1c; /* Even darker on hover */
}

.form-group {
  margin-top: 20px;
}

.input {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: white;
  background-color: #2d2d2d; /* Darker grey for the button */


}

.add-pdf-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #007BFF; /* Bootstrap primary color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-pdf-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}


.add-button {
  background-color: #4caf50; /* Green button */
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.add-button:hover {
  background-color: #45a049; /* Slightly darker green on hover */
}

