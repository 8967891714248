.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #333;  /* Dark background */
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
  }
  

  .dark-textarea {
    background-color: #333;  /* Dark background */
    color: #fff;             /* Light text color */
    padding: 10px;
    border: 1px solid #444;  /* Optional darker border */
    width: 100%;
    font-size: 16px;
    border-radius: 4px;
  }
  

  .cancel-button, .ok-button {
    margin-right: 10px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .cancel-button {
    background-color: #d30808;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  .ok-button {
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  .ok-button:hover, .cancel-button:hover {
    opacity: 0.8;
  }
  