.flashcard-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .flashcard-header h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .flashcard-main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .flashcard-box {
    width: 300px;
    height: 200px;
    border: 2px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    cursor: pointer;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    color: black;
  }
  
  .flashcard-navigation button {
    margin: 0 10px;
  }
  
  .flashcard-footer {
    margin-top: 20px;
  }
  


/* FlashcardPage.css */

/* Styles for the TypeAnswerFlashcard component */
.type-answer-flashcard {
  margin: 0 auto;
  max-width: 500px;
  text-align: center;
}

.type-answer-flashcard .question {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.type-answer-flashcard .result {
  margin-top: 1rem;
}

.type-answer-flashcard input[type="text"] {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
}

.type-answer-flashcard button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
