/* src/pages/DashboardPage.css */
.dashboard-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  
  .dashboard-container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #58a6ff;
  }
  
  .widgets {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .widget {
    background: #161b22;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #c9d1d9;
  }
  
  .widget h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #58a6ff;
  }
  
  .widget ul {
    list-style-type: none;
    padding: 0;
  }
  
  .widget li {
    margin: 10px 0;
  }
  
  .expandable-units {
    margin-top: 40px;
  }
  
  .expandable-units h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #58a6ff;
  }
  
  .expandable-units ul {
    list-style-type: none;
    padding: 0;
  }
  
  .expandable-units li {
    background: #161b22;
    margin: 10px 0;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #c9d1d9;
  }
  
  .unit-header {
    font-size: 1.5rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .decks-list {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
  }
  
  .deck-item {
    background: #0d1117;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .deck-name {
    font-size: 1.2rem;
  }
  
  .deck-item button {
    padding: 5px 10px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  