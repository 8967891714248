/* src/pages/EditDeckPage.css */
.edit-deck-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .title {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
  }
  
  .subtitle {
    color: #555;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  .flashcard-list {
    list-style-type: none;
    padding: 0;
  }
  
  .flashcard-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #4e4e4e; /* Greyish background for each deck */
    color: white; /* White text color */
    padding: 15px 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .delete-button {
    background-color: #dc3545;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }
  
  .input {
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .add-button {
    align-self: flex-start;
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 10px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-button:hover {
    background-color: #218838;
  }
  
  .loading {
    text-align: center;
    margin-top: 50px;
    font-size: 20px;
    color: #777;
  }
  