
/* Adjust the content inside the header */



.header-content {
    max-width: 1240px; /* Limit the inner content width */
    margin: 0 auto; /* Center the content */
    padding: 0 20px; /* Add padding for responsiveness */
  
    
  }
  
  .nav-links {
    font-size: 18px; /* Increase the font size */
  }
  
  
  .header nav {
    display: flex;
    justify-content: center;
  }
  
  .header ul {
    list-style: none;
    display: flex;
    gap: 20px;
  }
  
  
  .user-icon {
    font-size: 48px;
    color: #cacaca;
  }
  
   .user-image {
    font-size: 48px;
    color: #000;
    cursor: pointer;
  }
  
  .relative {
    position: relative;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;  /* Position the dropdown right below the icon */
    right: 0;   /* Align to the right edge of the relative container */
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 8px;
    z-index: 100;
  }
  
  .dropdown-item {
    padding: 8px 16px;
    cursor: pointer;
    color: #333;
    background-color: transparent;
    border: none;
    text-align: left;
    width: 100%;
    white-space: nowrap; /* Ensure text stays on one line */
  
  }
  
  .dropdown-item:hover {
    background-color: #f1f1f1;
  }