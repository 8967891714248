/* src/pages/UnitsPage.css */
.units-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  .units-container h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #58a6ff;
  }
  
  .units-container ul {
    list-style-type: none;
    padding: 0;
  }
  
  .units-container li {
    background: #161b22;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    color: #c9d1d9;
    cursor: pointer;
  }
  
  .units-container li.selected {
    background: #28a745;
    color: #fff;
  }
  
  .units-container button {
    padding: 10px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  