.user-profile-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .profile-header {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    display: block;
    margin: 10px auto; /* Change this line */
  }
  
  
  .profile-image-placeholder {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #ddd;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #777;
    margin: 0 auto;
  }
  
  .profile-details {
    font-size: 16px;
    line-height: 1.6;
  }
  
  .profile-details p {
    margin: 10px 0;
  }
  
  .user-profile-container button {
    display: block;
    margin: 30px auto 0;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  