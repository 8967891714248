/* src/pages/SignupPage.css */
.signup-container {
    max-width: 400px;
    margin: 100px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .signup-container h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .signup-container form {
    display: flex;
    flex-direction: column;
  }
  
  .signup-container label {
    margin-bottom: 5px;
  }
  
  .signup-container input {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .signup-container button {
    padding: 10px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .signup-container .error {
    color: red;
    margin-bottom: 15px;
    text-align: center;
  }
  