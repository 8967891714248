/* src/pages/UserInfoPage.css */
.user-info-container {
    max-width: 600px;
    margin: 100px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: #000; /* Set text color to black */
  }
  
  .user-info-container h2 {
    margin-bottom: 20px;
    text-align: center;
    color: #000; /* Set text color to black */
  }
  
  .user-info-container form {
    display: flex;
    flex-direction: column;
  }
  
  .user-info-container label {
    margin-bottom: 10px;
    text-align: left;
    color: #000; /* Set text color to black */
  }
  
  .user-info-container input,
  .user-info-container select {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    color: #000; /* Set text color to black */
  }
  
  .user-info-container button {
    padding: 10px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .error {/* src/pages/UserInfoPage.css */
    .user-info-container {
      max-width: 600px;
      margin: 100px auto;
      padding: 20px;
      border: 1px solid #ddd;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      color: #000; /* Set text color to black */
    }
    
    .user-info-container h2 {
      margin-bottom: 20px;
      text-align: center;
      color: #000; /* Set text color to black */
    }
    
    .user-info-container form {
      display: flex;
      flex-direction: column;
    }
    
    .user-info-container label {
      margin-bottom: 10px;
      text-align: left;
      color: #000; /* Set text color to black */
    }
    
    .user-info-container input,
    .user-info-container select {
      margin-bottom: 10px;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      color: #000; /* Set text color to black */
    }
    
    .user-info-container button {
      padding: 10px;
      background-color: #28a745;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
    
    .error {
      color: red;
      font-size: 0.9rem;
    }
    
    color: red;
    font-size: 0.9rem;
  }
  

  .current-profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    display: block;
    margin: 10px 0;
  }
  