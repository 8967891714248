/* src/pages/HomePage.css */
body {
  margin: 0;
  font-family: 'Source Code Pro', monospace;
  background: #0d1117;
  color: #c9d1d9;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.home-container {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

.bouncing-balls-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none; /* Ensure background is not interactive */
}

.content {
  position: relative;
  z-index: 1;
  padding: 20px;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto; /* Allow vertical scrolling */
}

.welcome-section, .release-notes-section, .video-section, .about-section, .footer-section {
  margin-bottom: 40px;
  width: 100%;
  max-width: 800px;
  text-align: center;
}

.welcome-section h1 {
  font-size: 2.5rem;
  color: #ffffff;
}

.welcome-section p {
  font-size: 1.2rem;
  color: #c9d1d9;
  margin-bottom: 20px;
}

.welcome-section button {
  background: #58a6ff;
  color: #0d1117;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.release-notes-section h2, .video-section h2, .about-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #58a6ff;
}

.release-notes-section ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
}

.release-notes-section button {
  background: #58a6ff;
  color: #0d1117;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
}

.video-section iframe {
  max-width: 100%;
  border: none;
  border-radius: 10px;
}

.about-section img {
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.footer-section {
  background: #161b22;
  padding: 20px;
  width: 100%;
  text-align: center;
}

.footer-section p {
  margin: 5px 0;
  color: #8b949e;
}

.footer-section a {
  color: #58a6ff;
  text-decoration: none;
}
